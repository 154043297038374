import PropTypes from 'prop-types'
import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '@redux/root-reducer'
import {validateAuth} from '@redux/slices/auth-slice'
import {navigate} from '@redux/slices/navigation-slice'
import {LanguageContext} from '@utils/context'
import {CommonProps} from '@utils/types'

/**
 * IndexRedirect
 */
interface IndexRedirectProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

const IndexRedirect: React.FC<IndexRedirectProps> = () => {
	const {activeLanguage} = useContext(LanguageContext)

	const dispatch = useDispatch()

	const {isAuthenticated, validationFinished} = useSelector(
		(state: RootState) => state.auth
	)

	useEffect(() => {
		if (!validationFinished) {
			return
		}

		if (isAuthenticated) {
			dispatch(
				navigate(`/${activeLanguage}/app`, {
					replace: true,
				})
			)
		}
	}, [isAuthenticated, validationFinished])

	useEffect(() => {
		dispatch(validateAuth())
	}, [dispatch])

	return <div id="index-redirect-page" />
}

IndexRedirect.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
}

export default IndexRedirect
